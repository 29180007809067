import * as React from "react"
import { Link } from "gatsby"

export const Content = () => {
  return (
    <>
      <div className="container pageBodyBox">
        <section id="description">
          <p>トナリノは「つくりたいものがある」けれど「悩んでしまう」つくり手の方に向けて、創作をするデザイナーが立ち上げたアドバイザリーサービスです。</p>

          <p>
            「ツールが使えるといっても、どんな風に使っていいのかわからない」<br />
            「こういうことがしたいけれど、うまくできなくて困っている」<br />
            「デザインのことで悩んでいるけれど、だれかに相談できたらうれしいな」<br />
            そんな悩みを教えていただき、一緒に最適解を考えます。
          </p>

          <p>お手伝いの仕方はチャットツール（LINEを使用します）でのやりとり、もしくはGoogle Meetでのオンライン通話での画面共有（ビデオ通話はいたしません）など、お悩みに合わせたかたちでご提案いたします。</p>
          <p className="mb-5">悩んでいる方の気持ちがちょっと楽になる、安心できる、そんな立場になれたらうれしいです。</p>
        </section>
        <section id="canDo">
          <h2 className="fs-2 fw-bold mb-2">できること</h2>
          <ul>
              <li className="mb-0">ご自身が制作されたものに対するご相談</li>
              <li className="mb-0">デザインに関するご質問、困りごとを一緒に考え、解決方法を一緒に探すこと</li>
              <li className="mb-0">ご相談いただき、それに対する一デザイナーとしての意見を整理し、ご提案すること</li>
          </ul>
        </section>
        <section id="cannotDo">
          <h2 className="fs-2 fw-bold mb-2">できないこと</h2>
          <ul>
              <li className="mb-0">即時の解決</li>
              <li className="mb-0">
                必ずご満足いただくという保証
                <ul className="mt-0 mb-1">
                  <li className="mb-0">私のスキルの範囲で懸命にアドバイスを行いますが、ご相談内容によっては期待に添えない可能性がございます</li>
                </ul>
              </li>
              <li className="mb-0">対面での面談、ご相談</li>
              <li className="mb-0">
                ご相談者様本人以外が制作されたものに関するご相談
                <ul className="mt-0 mb-1">
                  <li className="mb-0">ご自身が一部制作されたものである場合は、その範囲内で困ったことをご相談いただくのみ可能です</li>
                </ul>
              </li>
          </ul>
        </section>
        <section id="customer">
          <h2 className="fs-2 fw-bold mb-2">ご利用できる方</h2>
          <ul>
              <li className="mb-0">成人されており、支払い能力のある社会人の方</li>
              <li className="mb-0">ほかのデザイナーからコーチング等アドバイスを受けていない方（トラブルを避けるため）</li>
              <li className="mb-0">（画面共有等、通話がある場合）通話をする通信環境・端末の環境が整っている方</li>
              <li className="mb-0">対応言語は日本語のみです</li>
          </ul>
        </section>
        <section id="payment">
          <h2 className="fs-2 fw-bold mb-2">決済について</h2>
          <ul>
              <li className="mb-0">決済はクレジットカード・デビッドカードです（使用できるのはVisa・MasterCard・American Express・JCB・Diners Club・Discoverです。今後カード以外の決済方法を増やしていく予定です）<br />
                ほかの決済方法をご希望の方は、依頼時にご相談ください。
              </li>
              <li className="mb-0">
              BOOTHでのやりとりをご希望の場合はBOOTHの決済方法をご利用いただけます。</li>
              <li className="mb-0">当サービスは完全前金制です。ご相談内容を伺ったあとに決済URLを発行しますので、ご決済いただき次第サービスを開始いたします</li>
              <li className="mb-0">クレジットカードの決済はStripeを利用します</li>
          </ul>
        </section>
        <p className="text-center mt-4 mb-5">
          <Link to="/plans" className="btn btn-primary btn-fixed-width rounded-pill py-3">
            詳しいプランをみる
          </Link>
        </p>
      </div>
    </>
  )
}
