import * as React from "react"
import { Content } from "../components/about/Content"

import Layout from "../components/layout"
import PageTitleBox from "../components/PageTitleBox"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="トナリノとは？,トナリノ,デザイン,デザイン相談"
      description="トナリノのサービスについて、ご利用できる方、決済方法についてご案内しています。"
      ogTitle="トナリノとは？"
      ogDescription="トナリノのサービスについて"
      ogUrl="https://tonarino.co/about"
    />
    <PageTitleBox title="トナリノとは？" />
    <section id="content">
      <Content />
    </section>
  </Layout>
)

export default IndexPage
